import { go, goSync } from '@api3/promise-utils';
import { Box, Button, Notification, TextInput, Title } from '@mantine/core';
import { Web3NetworkSwitch } from '@web3modal/react';
import { ethers } from 'ethers';
import Head from 'next/head';
import { signErc2612Permit } from 'oev-contracts';
import { useState } from 'react';
import { useNetwork, useSigner } from 'wagmi';

import { AccountInfoForm } from '../components/account-info-form';
import { LoginButton } from '../components/login-button';
import { getPrepaymentDepositoryNetworks, isPrepaymentDepositoryNetwork } from '../src/chain';
import { usePrepaymentDepositoryContract, useTokenContract, useVerifyContractDeployments } from '../src/hooks';
import { logger } from '../src/logger';

const DepositForm = () => {
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const prepaymentDepository = usePrepaymentDepositoryContract();
  const token = useTokenContract();
  const [depositAmount, setDepositAmount] = useState('');
  const [depositAddress, setDepositAddress] = useState('');
  const [depositNotificationError, setDepositNotificationError] = useState<string | null>();
  const [depositNotificationSuccess, setDepositNotificationSuccess] = useState<string | null>();
  const supportedNetwork = chain && !chain.unsupported && isPrepaymentDepositoryNetwork(chain.id);

  const deposit = async () => {
    if (!prepaymentDepository || !chain || !token || !signer) return;

    const goAmount = goSync(() => ethers.BigNumber.from(depositAmount));
    if (!goAmount.success) {
      setDepositNotificationError('Invalid deposit amount');
      logger.error('Invalid deposit amount', goAmount.error);
      return;
    }
    if (!ethers.utils.isAddress(depositAddress)) {
      setDepositNotificationError('Invalid deposit address');
      logger.error('Invalid deposit address', { depositAddress });
      return;
    }

    const goTx = await go(async () => {
      const deadline = ethers.constants.MaxUint256;
      const { v, r, s } = await signErc2612Permit(
        token,
        signer as ethers.Wallet,
        prepaymentDepository.address,
        goAmount.data,
        deadline
      );
      return prepaymentDepository.applyPermitAndDeposit(depositAddress, goAmount.data, deadline, v, r, s);
    });
    if (!goTx.success) {
      setDepositNotificationError('Deposit failed');
      logger.error('Deposit failed', goTx.error);
    } else {
      setDepositNotificationSuccess('Deposit successful');
      logger.info('Deposit successful', { deposit: goTx.data });
    }
  };

  if (!supportedNetwork) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: 50, flexDirection: 'column' }}>
        <span>
          Network <b>{chain?.id ?? 'unknown'}</b> is not OEV payment supported network. Please use one of the following
          networks
        </span>
        <div>
          <ul>
            {getPrepaymentDepositoryNetworks().map((network) => (
              <li key={network}>{network}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <Box sx={{ margin: 'auto' }}>
      <Box sx={{ display: 'flex', margin: 10, '& > *:not(:last-child)': { marginRight: 8 } }}>
        <TextInput placeholder="Amount" onChange={(e) => setDepositAmount(e.target.value)}></TextInput>
        <TextInput placeholder="Deposit address" onChange={(e) => setDepositAddress(e.target.value)}></TextInput>
        <Button onClick={deposit}>Deposit</Button>
      </Box>
      <div style={{ margin: 10 }}>
        {depositNotificationSuccess && (
          <Notification color="teal" onClose={() => setDepositNotificationSuccess(null)}>
            {depositNotificationSuccess}
          </Notification>
        )}
        {depositNotificationError && (
          <Notification color="red" onClose={() => setDepositNotificationError(null)}>
            {depositNotificationError}
          </Notification>
        )}
      </div>
    </Box>
  );
};

export default function Home() {
  useVerifyContractDeployments();
  const { chain } = useNetwork();

  return (
    <>
      <Head>
        <title>OEV relay</title>
        <meta name="description" content="Created by API3" />
      </Head>
      <main>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ margin: 'auto', textAlign: 'center' }}>
            <Title sx={{ margin: 30 }}>Welcome to API3 OEV relay!</Title>
            <Box sx={{ margin: '30px auto' }}>
              <LoginButton />
            </Box>

            {!chain && (
              <>
                <Title order={4} sx={{ marginBottom: 10 }}>
                  Get started by connecting Metamask
                </Title>
              </>
            )}
            {chain && chain.unsupported && (
              <>
                <Title order={4} sx={{ marginBottom: 10 }}>
                  Please switch to one of the supported networks
                </Title>
                <Web3NetworkSwitch />
              </>
            )}
            {chain && !chain.unsupported && <AccountInfoForm />}
          </Box>
          {chain && !chain.unsupported && <DepositForm />}
        </Box>
      </main>
    </>
  );
}
