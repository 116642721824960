import { go, goSync } from '@api3/promise-utils';
import { AccessControlRegistry__factory, ERC20Permit__factory, PrepaymentDepository__factory } from 'oev-contracts';
import { useEffect, useMemo } from 'react';
import { Address, useAccount, useBalance, useNetwork, useSigner } from 'wagmi';

import { getAccessControlRegistryAddress, getPrepaymentDepositoryAddress, getTokenAddress } from './chain';
import { logger } from './logger';

export const usePrepaymentDepositoryContract = () => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  const contract = useMemo(() => {
    if (!chain || !signer) return null;

    const goPrepaymentDepositoryAddress = goSync(() => getPrepaymentDepositoryAddress(chain.id));
    // We are not logging the error to console, because effects are run multiple times in development and this produces
    // a lot of noise. Unsupported networks are handled by the FE explicitly.
    if (!goPrepaymentDepositoryAddress.success) return null;

    return PrepaymentDepository__factory.connect(goPrepaymentDepositoryAddress.data, signer);
  }, [chain, signer]);

  return contract;
};

export const useAccessControlRegistryContract = () => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  const contract = useMemo(() => {
    if (!chain || !signer) return null;

    const goAccessControlRegistry = goSync(() => getAccessControlRegistryAddress(chain.id));
    // We are not logging the error to console, because effects are run multiple times in development and this produces
    // a lot of noise. Unsupported networks are handled by the FE explicitly.
    if (!goAccessControlRegistry.success) return null;

    return AccessControlRegistry__factory.connect(goAccessControlRegistry.data, signer);
  }, [chain, signer]);

  return contract;
};

export const useTokenContract = () => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  const contract = useMemo(() => {
    if (!chain || !signer) return null;

    const goTokenAddress = goSync(() => getTokenAddress(chain.id));
    // We are not logging the error to console, because effects are run multiple times in development and this produces
    // a lot of noise. Unsupported networks are handled by the FE explicitly.
    if (!goTokenAddress.success) return null;

    return ERC20Permit__factory.connect(goTokenAddress.data, signer);
  }, [chain, signer]);

  return contract;
};

export const useVerifyContractDeployments = () => {
  const token = useTokenContract();
  const prepaymentDepository = usePrepaymentDepositoryContract();
  const accessControlRegistry = useAccessControlRegistryContract();
  const { chain } = useNetwork();

  useEffect(() => {
    // Check that the localhost contract is callable. It's a common mistake to refer to past localhost deployments. We can
    // assume that deployments on real chains will be valid and the "deployments" inside contracts are up to date.
    const verifyDeployments = async () => {
      if (!prepaymentDepository || !token || !accessControlRegistry || chain?.id !== 31337) return;

      const goCallToken = await go(() => token.totalSupply());
      if (!goCallToken.success) {
        logger.error('Token contract is not callable. Its address probably refers to a past deployment.');
        return;
      }

      const goCallPrepaymentDepository = await go(() => prepaymentDepository.token());
      if (!goCallPrepaymentDepository.success) {
        logger.error(
          'PrepaymentDepository contract is not callable. Its address probably refers to a past deployment.'
        );
        return;
      }

      const goCallAccessControlRegistry = await go(() =>
        accessControlRegistry.isTrustedForwarder(accessControlRegistry.address)
      );
      if (!goCallAccessControlRegistry.success) {
        logger.error(
          'AccessControlRegistry contract is not callable. Its address probably refers to a past deployment.'
        );
        return;
      }
    };

    verifyDeployments();
  }, [chain, token, prepaymentDepository, accessControlRegistry]);
};

export const useEthBalance = () => {
  const { address } = useAccount();
  const result = useBalance({ address, watch: true });

  return result.data?.formatted;
};

export const useTokenBalance = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const goTokenAddress = goSync(() => {
    if (!chain) throw new Error('Not logged in yet');
    return getTokenAddress(chain.id) as Address;
  });
  const result = useBalance(goTokenAddress.success ? { address, token: goTokenAddress.data, watch: true } : undefined);

  return result.data?.formatted;
};
