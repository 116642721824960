import * as Sentry from '@sentry/nextjs';
import { Logger as TslogLogger } from 'tslog';

import { loadEnv } from './env';

// See: https://tslog.js.org/#/?id=minlevel
type LogLevel = 'silly' | 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';
const logLevels = {
  silly: 0,
  trace: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
  fatal: 6,
};

function logLevelToNumber(level: LogLevel): number {
  return logLevels[level];
}

const env = loadEnv();

const _logger = new TslogLogger<any>({
  // The log position clutters the log output and downgrades the performance. Usually, the source position can be found
  // just from the log message. Also, the terminal integration with one-click-to-log-position doesn't work properly. Due
  // to this we hide the log position on all environments.
  hideLogPositionForProduction: true,
  minLevel: logLevelToNumber(env.NEXT_PUBLIC_LOG_LEVEL),
  type: env.NEXT_PUBLIC_LOG_TYPE,
  stylePrettyLogs: env.NEXT_PUBLIC_LOG_STYLING === 'on',
});

_logger.attachTransport((logObj) => {
  const meta = logObj._meta;
  const logLevel = logObj._meta.logLevelName.toLowerCase();

  // Only send the error to Sentry if the log level is ERROR or FATAL.
  if (logLevel !== 'error' && logLevel !== 'fatal') return;

  // The logger arguments adhere to the Logger interface (defined below).
  const message = logObj[0];
  const errorOrContext = logObj[1];
  const error = errorOrContext && 'nativeError' in errorOrContext ? errorOrContext.nativeError : null;
  const context = errorOrContext && 'nativeError' in errorOrContext ? logObj[2] : null;

  Sentry.withScope((scope) => {
    scope.setTag('logger_name', meta.name);
    scope.setLevel(logLevel);
    scope.setTag('date', meta.date); // The event's timestamp reflects the time it occurred. However, Sentry's date will correspond to the moment the event was received by the platform.

    if (!error) {
      Sentry.captureMessage(message, scope);
      return;
    }

    scope.setTag('custom_error_description', message);
    scope.addBreadcrumb({
      category: 'error',
      message,
      level: logLevel,
    });
    for (const [key, value] of Object.entries(context ?? {})) {
      scope.setExtra(key, value);
    }

    Sentry.captureException(error, scope);
  });
});

export type LogContext = Record<string, unknown>;

export interface Logger {
  debug(message: string, context?: LogContext): void;
  info(message: string, context?: LogContext): void;
  error(message: string, context?: LogContext): void;
  error(message: string, error: Error, context?: LogContext): void;
  getSubLogger(options: { name: string }): Logger;
}

export const logger = _logger as Logger;
