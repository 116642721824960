import { Col, Container, Grid, Text } from '@mantine/core';
import React from 'react';
import { useAccount, useNetwork } from 'wagmi';

import { useEthBalance, useTokenBalance } from '../src/hooks';

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

const abbreviateAddress = (address: string) => {
  return address.substring(0, 9) + '...' + address.substring(address.length - 6, address.length);
};

export const AccountInfoForm = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const ethBalance = useEthBalance();
  const tokenBalance = useTokenBalance();

  return (
    <Container sx={{ width: 500 }}>
      <Grid>
        <Col sx={rowStyle}>
          <Text>User Account:</Text>
          <Text weight={500}>{address ? abbreviateAddress(address) : ''}</Text>
        </Col>
        {chain && (
          <Col sx={rowStyle}>
            <Text>Chain ID:</Text>
            <Text weight={500}>{chain.id}</Text>
          </Col>
        )}
        {chain && ethBalance && (
          <Col sx={rowStyle}>
            <Text>{chain.nativeCurrency.symbol} Balance:</Text>
            <Text weight={500}>{ethBalance}</Text>
          </Col>
        )}
        {tokenBalance && (
          <Col sx={rowStyle}>
            <Text>Token Balance:</Text>
            <Text weight={500}>{tokenBalance}</Text>
          </Col>
        )}
      </Grid>
    </Container>
  );
};
